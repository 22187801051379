
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Link,
} from "@mui/material";
import Prism from "prismjs";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-okaidia.css";
import CONFIG from "../config";

export default function Chat() {
  const [input, setInput] = React.useState("");
  const [showIcon, setShowIcon] = React.useState(true);
  const [isTyping, setIsTyping] = useState(false);

  const [messages, updateMessages] = React.useState([
    { id: 1, text: "How can I help today?", sender: "bot" },
    // { id: 1, text: "How can I help today?", sender: "user" },
    // { id: 1, text: "How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?", sender: "user" },

    //  { id: 1, text: "How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?How can I help today?", sender: "user" },
  ]);
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
    Prism.highlightAll();
  }, [messages]);

  const handleSend = async () => {
    setIsTyping(true);

    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    if (!input.trim()) return;

    // Add user message
    const userMessage = {
      id: messages.length + 1,
      text: input,
      sender: "user",
    };
    const newMessages = [...messages, userMessage];
    updateMessages(newMessages);
    setInput("");
    setShowIcon(false);

    try {
      const response = await fetch(`${CONFIG.BACKEND_URL}/api/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ messages: newMessages }),
      });

      if (!response.ok) throw new Error("Network error");

      const { text, sql, data } = await response.json();

      // Add bot message
      const botMessage = {
        id: newMessages.length + 1,
        sender: "bot",
        text,
        sql,
        data,
      };
      updateMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        id: messages.length + 1,
        sender: "bot",
        text: "Sorry, I'm having trouble connecting to the server. Please try again later.",
      };
      updateMessages((prev) => [...prev, errorMessage]);
    }
    setIsTyping(false);
  };

  const handleInputChange = (event) => setInput(event.target.value);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/assets/amrum.jpg)`,
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      {/* Header */}
      <Box sx={{
        p: 2,
        backgroundColor: "#303030",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 1
      }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            textAlign: "center"
          }}
        >
          Blue Chat
        </Typography>
      </Box>


      {/* Messages container */}
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative'
      }}>
        <Box sx={{
          flex: 1,
          overflowY: 'auto',
          p: 2,
          position: 'relative',
          // Add momentum scrolling for mobile
          WebkitOverflowScrolling: 'touch'
        }}>
          {messages.map((message) => (
            <Message key={message.id} message={message} />
          ))}

          {isTyping && (
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: "white",
                  borderRadius: "20px 20px 20px 5px",
                  backdropFilter: "blur(10px)",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="/assets/typing.gif" alt="..." width="40" />
                <Typography variant="body1" sx={{ ml: 1, color: "black" }}>
                  Typing...
                </Typography>
              </Paper>
            </Box>
          )}

          <div ref={messagesEndRef} />

          {/* Overlay image */}
          <img
            src="/assets/blue.png"
            alt="Assistant"
            style={{
              display: showIcon ? "block" : "none",
              margin: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0.2,
              pointerEvents: 'none'
            }}
            height="150px"
          />
        </Box>
      </Box>

      {/* Input container (stays fixed at bottom) */}
      <Box sx={{
        p: 2,
        backgroundColor: "#303030",
        flexShrink: 0 // Prevent input area from shrinking
      }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your question..."
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              multiline
              maxRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "25px",
                  backgroundColor: "background.default",
                },
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSend}
              sx={{ height: "56px", borderRadius: "25px", background: "rgba(0, 123, 255, 0.7)" }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const Message = ({ message }) => {
  const isBot = message.sender === "bot";

  function fetchImageData(date, highlightImage) {
    const imageUrl = `${CONFIG.BACKEND_URL}/api/images/${date}/compressed/${highlightImage}`;
    const token = localStorage.getItem('token');

    return fetch(imageUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.blob())
      .then(blob => {
        return URL.createObjectURL(blob); // Create a blob URL to be used as an image source
      })
      .catch(error => {
        console.error('Error fetching image data:', error);
        return null;
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Paper
        sx={{
          p: 2,
          maxWidth: "90%",
          backgroundColor: isBot
            ? "rgba(255, 255, 255, 0.7)" // semi-transparent white for bot
            : "rgba(0, 123, 255, 0.7)", // semi-transparent blue for user (you can change the color)

          borderRadius: isBot
            ? "20px 20px 20px 5px"
            : "20px 20px 5px 20px",
          backdropFilter: "blur(10px)", // adding the frosted glass effect
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // subtle shadow for more depth
        }}
      >
        {message.text && (
          <Typography
            variant="body1"
            sx={{
              mb: message.sql || message.data ? 2 : 0,
              color: isBot ? "black" : "white",
              padding: "10px"
            }}
            dangerouslySetInnerHTML={{ __html: message.text }}
          />
        )}

        {message.sql && (
          <Paper
            component="pre"
            sx={{
              p: 2,
              mb: 2,
              overflowX: "auto",
              backgroundColor: "background.default",
              "& code": {
                fontFamily: "monospace",
                fontSize: "0.8rem",
              },
            }}
          >
            <code className="language-sql">{message.sql}</code>
          </Paper>
        )}
        {message.data && message.data.length > 0 &&
          message.data.some(item => item && item.date) && (
            <Grid container spacing={2}>
              {message.data.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <a
                    href={`/dashboard/day#${item.date}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      display: 'block',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        backgroundColor: 'background.default',
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      {item.highlight_image && item.date && (
                        <FetchImage date={item.date} highlightImage={item.highlight_image} />
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'black',
                        }}
                      >
                        {item.date}
                      </Typography>
                    </Paper>
                  </a>
                </Grid>
              ))}
            </Grid>
          )}

      </Paper>
    </Box>
  );
};

const FetchImage = ({ date, highlightImage }) => {
  const [imageUrl, setImageUrl] = useState(null);


  function fetchImageData(date, highlightImage) {
    const imageUrl = `${CONFIG.BACKEND_URL}/api/images/${date}/compressed/${highlightImage}`;
    const token = localStorage.getItem('token');

    return fetch(imageUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.blob())
      .then(blob => {
        return URL.createObjectURL(blob); // Create a blob URL to be used as an image source
      })
      .catch(error => {
        console.error('Error fetching image data:', error);
        return null;
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      const image = await fetchImageData(date, highlightImage);
      setImageUrl(image); // Set the image URL after it's fetched
    };

    fetchData();
  }, [date, highlightImage]);

  return imageUrl ? (
    <img
      src={imageUrl}
      alt="Highlight"
      style={{
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        borderRadius: '8px',
        marginBottom: '8px',
      }}
    />
  ) : (
    <div>Loading...</div> // Optionally, you can show a loading message or spinner
  );
};

