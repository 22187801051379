import React, { useEffect } from 'react';

import { Navigate, useRoutes , useLocation} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import MapPage from './pages/MapPage';
import DayPage from './pages/DayPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import ScraperPage from './pages/ScraperPage';
import RunDetails from './pages/RunDetails';
import DashboardAppPage from './pages/DashboardAppPage';
import ComparePage from './pages/ComparePage';
import BigImage from './pages/BigImage';
import {SearchContextProvider} from './context/SearchContext';
import PersonsPage from './pages/Persons';
import RunsPage from './pages/RunsPage';
import Chat from './pages/Chat';
import Highlights from './pages/Highlights';

// ----------------------------------------------------------------------

const onSearch = (event) => {
  console.log("yes");
};
export default function Router() {
  const location = useLocation();

  useEffect(() => {
    const updateBodyBackgroundColor = () => {
      console.log(location)
      console.log("location")
      if(location.pathname === '/dashboard/image')
      document.body.style.backgroundColor = '#202020';
      else if(location.pathname === '/login')
      document.body.style.backgroundColor = '#111';
      else if(location.pathname === '/dashboard/Highlights')
      document.body.style.backgroundColor = '#31485e';
      else
      document.body.style.backgroundColor = '#7f807f';
    };

    updateBodyBackgroundColor(); // Set initial background color
    const unlisten = () => {
      // The cleanup function for removing the body background color update
      document.body.style.backgroundColor = '';
    };

    return unlisten;
  }, [location]);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
          <DashboardLayout onSearch={onSearch} />
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
              <DashboardAppPage />
          ),
        },
        { path: 'maps', element: <MapPage /> },
        { path: 'day', element: <DayPage /> },
        { path: 'files', element: <ComparePage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'run', element: <RunDetails /> },
        { path: 'persons', element: <PersonsPage /> },
        { path: 'runs', element: <RunsPage /> },
        { path: 'highlights', element: <Highlights /> },
       
      ],
    },
    { path: 'chat', element: <Chat /> },
    { path: '/dashboard/chat', element: <Chat /> },
    {
      path: '/dashboard/app',
      element: (
        <SearchContextProvider>
          <DashboardLayout />
        </SearchContextProvider>
      ),
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    { path: '/dashboard/image', element: <BigImage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
